.gpt3__header{
    display: flex;
}

.gpt3__header-content{
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    margin: 5rem;
}

.gpt3__header-content h1{
font-family: var(--font-family);
font-weight: 800;
font-size: 62px;
line-height: 75px;
/*or 121%*/
letter-spacing: -0.04em;
}

.gpt3__header-content p{
    font-family: Manrope;
    font-style: normal;
    font-weight: normal;
    font-size:20px;
    line-height: 27px;
    color:var(--color-text);
    margin: top 1.5rem;

}

.gpt3__header-content__input{
    width: 100%;
    margin: 2rem 0 1rem;
    display: flex;
}

.gpt3__header-content__input input{
    flex: 2;
    background: #052D56;
    border-radius: 5px 0px 0px 5px;
    font-family: var(--font-family);
    font-size:20px;
    line-height: 27px;
    color:var(--font-family);
    width: 100%;
    min-height: 50px;
    border: 2px solid var(--color-foter);
    padding: 0 1rem;
    border-radius: 5px 0px 0px 5px;
    color: #3D6184;
    outline: none;
    color: #fff;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.gpt3__header-content__input button{
    flex: 0.6;
    width: 100%;
    min-height: 50px;
    background: #ff4820;
    border-radius: 5px 0px 0px 5px;
   cursor: pointer;
    font-family: var(--font-family);
    font-weight: bold;
    font-size:20px;
    line-height: 27px;
    color: #fff;
    outline: none;
    padding: 0 1rem;
    border: none;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}
.gpt3__header-content__people {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 2rem;
}

.gpt3__header-content__people img{
    width: 182px;
    height: 38px;
}
.gpt3__header-content__people p {
    margin:0 0 0 1rem;
    font-style: normal;
    font-size: 12px;
    line-height: 38px;
    font-family: var(--font-family);
    font-weight: 500;
    color: #fff;
    text-align: center;
}

.gpt3__header-image{
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;

}

.gpt3__header-image img {
    height: 100%;
    width: 100%;
}

@media screen and (max-width:1050px) {
    .gpt3__header{
        flex-direction: column;
    }

    .gpt3__header-content{
        margin: 0 0 3rem;
    }
}

@media screen and (max-width:650px) {
    .gpt3__header h1{
        font-size: 48px;
        line-height: 60px;
    }

    .gpt3__header p{
        font-size: 16px;
        line-height: 24px;
    }

    .gpt3__header-content__people{
        flex-direction: column;
    }
    .gpt3__header-content__people p {
        margin: 0;
    }

    .gpt3__header-content__input input,
    .gpt3__header-content__input button {
        font-size: 16px;
        line-height: 24px;
    }
}

@media screen and (max-width:490px) {
    .gpt3__header h1{
        font-size: 36px;
        line-height: 48px;
    }

    .gpt3__header p{
        font-size: 14px;
        line-height: 24px;
    }

    .gpt3__header-content__input input,
    .gpt3__header-content__input button {
        font-size: 12px;
        line-height: 16px;
    }
}
